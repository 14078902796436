"use strict";


function enableSwipeOnSlickSlider(slider_selector) {
	const slider_el = document.querySelector(slider_selector)
	if (slider_el) {
		slider_el.addEventListener('wheel', function(e) {
			e.preventDefault()

			if (e.deltaY < 0) {
				$(slider_el).slick('slickPrev')
			} else {
				$(slider_el).slick('slickNext')
			}
		});
	}
}



/* Вставляет HTML или загружает внешние HTML-блоки
	*** what - html (string) или url для загрузки html
	*** props {
				request - GET-запрос для what URL
				type - тип загружаемого контента. Например, просто html, или modal, или notice и т.д.
			}
	*** notice {
			icon
			title
			text
			class
			autoclose
			close
		}
	*** noticesParentClass - название класса для общего списка notices
*/
class placeHTML {
  constructor(props) {
		const default_cfg = {
			html: null,
			url: null,
			debug: false,
			placeTo: 'body', // селектор или DOM-элемент, куда кладём новый элемент
			request: {},
			type: 'html',
			position: 'after', // TODO: придумать куда применить
			noticesParentClass: 'notices__wrap'
		}

		// применяем дефолтные настройки к поступившим
		Object.assign(this, {
			el: null,
			result: null,
			// loaded: null,
			cfg: Object.assign(default_cfg, props)
		})


		// настройки для notice
		if(typeof this.cfg.notice !== 'undefined') {
			this.cfg.notice = Object.assign({
				hasClose: true,
				position: 'top-center'
			}, this.cfg.notice)

			this.cfg.type = 'notice'
		}

		// если просто селектор - сразу выбираем элемент
		if(typeof this.cfg.placeTo === 'string')
			this.cfg.placeTo = document.querySelector(this.cfg.placeTo)


		this.cfg.debug ? console.info('(loadHTML START DEBUG): ', this):null

		switch (this.cfg.type) {
			case 'html': this.show(this.cfg.html); break
			case 'modal': this.show__modal(); break
			case 'notice': this.show__notice(); break

			default:
				if(this.cfg.html !== null) this.show(this.cfg.html)
					else if(this.cfg.url !== null) this.load(true)
					else console.warn('(loadHTML ERR: неизвестный тип контента', this); break;
		}

		this.cfg.debug ? console.info('(loadHTML END DEBUG): ', this):null
  }



  async load($show = false) {
    try {
			// если notice с url - значит параметры notice передаём как request
			if(typeof this.cfg.request != 'undefined' || (this.cfg.type == 'notice' && this.cfg.url != null)) {
				if(this.cfg.type == 'notice') {
					if(typeof this.cfg.request == 'undefined') this.cfg.request = {}
					this.cfg.request = Object.assign(this.cfg.notice, this.cfg.request)
				}

				this.cfg.url = `${this.cfg.url}?${new URLSearchParams(this.cfg.request).toString()}`
			}

      const response = await fetch(this.cfg.url, {
				mode: 'cors',
				credentials: 'same-origin',
				cache: 'no-cache',
			})

			if (!response.ok) throw new Error(response.statusText)
      this.result = await response.text()

			if($show) this.show(this.result)
			return this.result

    } catch (error) {
      console.error("(ERR loadHTML) Ошибка при загрузке HTML:", error);
    }
  }



	hide() {
		if(this.el !== null) this.el.innerHTML = null
	}



  show(html, placeTo = this.cfg.placeTo) {
		// this.el = (document.createElement('div').innerHTML = this.item)
		if(this.el === null) this.el = document.createElement('div')
		this.el.innerHTML = html
		placeTo.appendChild(this.el)

		this.el.classList.add('loadhtml__wrap', 'loadhtml__'+this.cfg.type)
		this.el.id = 'loadhtml--'+mt_rand()

		this.checkAndLoadAsyncScriptsAndCSS(this.el)
  }


	// проверка и активация скриптов и стилей из асинхронно вставлленного html
	checkAndLoadAsyncScriptsAndCSS() {
		// скрипты
		const scriptElements = this.el.querySelectorAll('script')
		scriptElements.forEach(script => {
			const newScript = document.createElement('script')
			if (script.src) {
				newScript.src = script.src;
			} else {
				newScript.textContent = script.textContent
			}
			this.cfg.debug ? console.info('append script', newScript) : null
			document.head.appendChild(newScript)
		})

		// стили
		const linkElements = this.el.querySelectorAll('link[rel="stylesheet"]')
		linkElements.forEach(link => {
			const newLink = document.createElement('link')
			newLink.rel = 'stylesheet'
			newLink.href = link.href
			document.head.appendChild(newLink)
			this.cfg.debug ? console.info('append css', newLink) : null
		})
	}



	show__modal() {  // настройка модалки
		this.load(true).then(() => {
			let new_modal = new bootstrap.Modal(this.el.querySelector('.modal'))
			this.el.addEventListener('hidden.bs.modal', () => { // удалять при закрытии
				// if(args.doNotUnset != true) {
					new_modal.dispose()
					this.el.remove()
					this.el = null
					// if(typeof this.addedHTMLel.script != 'undefined') this.addedHTMLel.script.remove();
					new_modal = null;
				// }
			})
			new_modal.show()
		})
	}


	async show__notice() {
		try {
			const noticesParentEl = this.cfg.placeTo == document.body
				? this.getNoticesParentEl()
				: this.cfg.placeTo

			const noticeHTML = this.cfg.url != null
				? await this.load()
				: this.create_notice()

			noticesParentEl.insertAdjacentHTML('beforeend', noticeHTML)
			setTimeout(()=>{
				if(noticesParentEl.lastElementChild != null) {
					noticesParentEl.lastElementChild.classList.add('show')
				}
			}, 10)

		} catch(error) {
			console.error('Show__notice loading error:', error);
		}
	}



	getNoticesParentEl() {
		let noticesParentPosition = this.cfg.noticesParentClass + '--' + this.cfg.notice.position // позиция parenEl
		let noticesParentSelector = '.' + this.cfg.noticesParentClass + '.' + noticesParentPosition // создаем селектор parentEl

		let noticesParentEl = document.querySelector(noticesParentSelector)
		if(noticesParentEl == null) {
			noticesParentEl = document.createElement("div")
			noticesParentEl.id = noticesParentPosition
			noticesParentEl.className = noticesParentSelector.replace(/\./g, ' ')
			document.body.appendChild(noticesParentEl)
		}
		return noticesParentEl
	}


	create_notice() {
		const notice = this.cfg.notice
		const htmlParts = {
			body:		'',
			classes:'notice fade alert',
			text: 	typeof notice.text != 'undefined' ? notice.text : null,
			title: 	typeof notice.title != 'undefined' ? notice.title : null,
			icon: 	typeof notice.icon != 'undefined' ? notice.icon : null,
			autoclose: 	typeof notice.autoclose != 'undefined' ? notice.autoclose : '',
		}

		// if(htmlParts.title != '') {
		// 	htmlParts.body += `<div class="notice__title">${htmlParts.title}</div>`
		// }
		// if(htmlParts.title != '' && htmlParts.text != '') {
		// 	htmlParts.body += `<div class="notice__text">${htmlParts.text}</div>`
		// } else {
		// 	htmlParts.body = `${htmlParts.text}`
		// }

		if(htmlParts.title == '') {
			htmlParts.body = `${htmlParts.text}`
		}
		else {
			htmlParts.body += `<div class="notice__title" role="alert">${htmlParts.title}</div><div class="notice__text">${htmlParts.text}</div>`
		}

		if(notice.icon) {
			htmlParts.classes += ' _media_obj'
			htmlParts.body = `<div class="_icon">${notice.icon}</div><div class="_body">${htmlParts.body}</div>`
		}

		if(notice.autoclose) {
			htmlParts.autoclose = ` data-alert-autoclose="${notice.autoclose}"`
		}

		if(notice.hasClose) {
			htmlParts.close = '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Закрыть"></button>'
			htmlParts.classes += ' alert-dismissible'
			htmlParts.body += htmlParts.close
		}

		if(notice.class) htmlParts.classes += ' '+notice.class

		let $return = ''
		$return += `<div class="${htmlParts.classes}" ${htmlParts.autoclose}>${htmlParts.body}</div>`

		return $return
	}
}


/* Переключает какой-то элемент
	*** toggleEl - переключаемый элемент, например: document.querySelector('body')
	*** toggleElClass - название класса
	*** selfEl - если при клике нужно переключить и себя (например, кнопку)
	*** toggleSelfElClass - класс для себя
	*** Пример: <button class="btn btn-secondary btn-icon btn__switch_theme" onclick="toggleItem({selfEl: document.querySelector('#header .btn__switch_theme'), callback: ()=> {toggleTemplateTheme()} })">Тема</button>
*/
window.toggleItem = (props)=>{
	new class {
		constructor() {
			const defaults = {
				toggleEl: null,
				toggleElClass: 'active',
				selfEl: null,
				toggleSelfElClass: 'active',

				backdrop: false,
				backdrop_html: '<div id="backdrop" class="offcanvas-backdrop fade"></div>',

				callback: null,
				isToggled: null
			}

			// если не нужен backdrop - удаляем инфу про него
			if(props.backdrop != true)
				delete defaults.backdrop_html

			// если пришел просто селектор в selfEl - заменяем на элемент
			if(typeof props.selfEl == 'string')
				props.selfEl = document.querySelector(props.selfEl)

			// если пришел просто селектор в toggleEl - заменяем на элемент
			if(typeof props.toggleEl == 'string')
				props.toggleEl = document.querySelector(props.toggleEl)

			// объединение настроек и проверка на обязательные данные
			this.cfg = Object.assign(defaults, props)
			if(this.cfg.toggleEl === null && this.cfg.callback === null) {
				throw new Error('ERR toggleItem(): не выбран ни один вариант (DOM или callback()) для переключения')
			}

			this.start()
		}


		// проверяет текущий статус toggle
		getCurrentState() {
			let result = null
			if(this.cfg.toggleEl !== null) {
				result = this.cfg.toggleEl.classList.contains(this.cfg.toggleElClass)
			} else if (this.cfg.selfEl !== null) {
				result = this.cfg.selfEl.classList.contains(this.cfg.toggleSelfElClass)
			}

			this.cfg.isToggled = result
			return result
		}


		start() {
			this.getCurrentState()

			// если выбран переключаемый элемент
			if(this.cfg.toggleEl !== null) {
				this.toggleItem(this.cfg.toggleEl, true)
			}
			else if(this.cfg.selfEl !== null) // если выбран только текущий элемент
				this.toggleItem(this.cfg.selfEl)

			// если выбран callback
			if(this.cfg.callback !== null && typeof this.cfg.callback == 'function')
				this.cfg.callback()
		}


		/**
		 * @param {*} item - триггерный элемент
		 * @param {boolean} [isItemToggledEl=false] -  - является ли item основным элементом (проверяется, нужно ли включать backdrop)
		 */
		toggleItem(item, isItemToggledEl = false) {
			if(typeof item != 'undefined') {
				const toggledClass = isItemToggledEl
					?	this.cfg.toggleElClass
					: this.cfg.toggleSelfElClass

				if(this.cfg.isToggled === false) {
					item.classList.add(toggledClass)
					if(isItemToggledEl) {
						this.toggleItem(this.cfg.selfEl)
						this.backdropShow()
					}
				}

				else if(this.cfg.isToggled === true) {
					item.classList.remove(toggledClass)
					if(isItemToggledEl) {
						this.toggleItem(this.cfg.selfEl)
						this.backdropHide()
					}
				}

			}
		}


		backdropShow() {
			if(this.cfg.backdrop != true) return false
			document.body.insertAdjacentHTML('beforeend', this.cfg.backdrop_html)
			// this.backdrop = document.querySelector('.offcanvas-backdrop')
			this.backdrop = document.body.lastElementChild
			setTimeout(()=>this.backdrop.classList.add('show'), 10)
			this.backdrop.addEventListener('click',()=>{
				this.cfg.isToggled = true
				this.toggleItem(this.cfg.toggleEl, true)
			})
		}


		backdropHide() {
			if(this.cfg.backdrop != true) return false
			const all_backdrops = document.querySelectorAll('.offcanvas-backdrop')
			all_backdrops.forEach(function(el) {
				el.classList.remove('show')
				setTimeout(()=>el.remove(), 5000)
			})
		}
	}
}



// Аналог mt_rand
function mt_rand(min = 0, max = 999999999) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}



function checkAlertAutoclose() {
	const alertList = document.querySelectorAll('[data-alert-autoclose]')
	alertList.forEach(function (alertEl) {
		const autoclose = alertEl.getAttribute('data-alert-autoclose')

		setTimeout(function() {
			new bootstrap.Alert(alertEl).close()
		}, autoclose)
	})
}
document.addEventListener("DOMContentLoaded", ()=>{checkAlertAutoclose()})
new MutationObserver(function(mutationsList) {checkAlertAutoclose()}).observe(document, {attributes: false, childList: true, subtree:true})
